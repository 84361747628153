export const links = [
  {
    id: 1,
    text: "home",
    url: "/home",
  },
  {
    id: 2,
    text: "choix d'images",
    url: "/image/",
  },
  {
    id: 3,
    text: "choix de textes",
    url: "/text/",
  },
  {
    id: 4,
    text: "bio",
    url: "/bio/",
  },
  {
    id: 5,
    text: "biblio",
    url: "/biblio/",
  },
];
