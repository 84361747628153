export const GET_ARTICLES_BEGIN = "GET_ARTICLES_BEGIN";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_ERROR = "GET_ARTICLES_ERROR";
export const GET_IMAGES_BEGIN = "GET_IMAGES_BEGIN";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const GET_IMAGES_ERROR = "GET_IMAGES_ERROR";
export const GET_SINGLE_IMAGE_BEGIN = "GET_SINGLE_IMAGE_BEGIN";
export const GET_SINGLE_IMAGE_SUCCESS = "GET_SINGLE_IMAGE_SUCCESS";
export const GET_SINGLE_IMAGE_ERROR = "GET_SINGLE_IMAGE_ERROR";
export const GET_SINGLE_ARTICLE_BEGIN = "GET_SINGLE_ARTICLE_BEGIN";
export const GET_SINGLE_ARTICLE_SUCCESS = "GET_SINGLE_ARTICLE_SUCCESS";
export const GET_SINGLE_ARTICLE_ERROR = "GET_SINGLE_ARTICLE_ERROR";
export const GET_BIBLIO_BEGIN = "GET_BIBLIO_BEGIN";
export const GET_BIBLIO_SUCCESS = "GET_BIBLIO_SUCCESS";
export const GET_BIBLIO_ERROR = "GET_BIBLIO_ERROR";
export const LOAD_DATA = "LOAD_DATA";
export const UPDATE_SORT = "UPDATE_SORT";
export const UPDATE_SORT_ARTICLES = "UPDATE_SORT_ARTICLES";
export const SORT_ARTICLES = "SORT_ARTICLES";
export const SORT_IMAGES = "SORT_IMAGES";
export const UPDATE_FILTERS_ARTICLES = "UPDATE_FILTERS_ARTICLES";
export const UPDATE_FILTERS_IMAGES = "UPDATE_FILTERS_IMAGES";
export const FILTER_ARTICLES = "FILTER_ARTICLE";
export const FILTER_IMAGES = "FILTER_IMAGES";
export const SET_GRIDVIEW = "SET_GRIDVIEW";
export const SET_LISTVIEW = "SET_LISTVIEW";
