import React from 'react';
import {FaInstagramSquare} from "react-icons/fa"

export const socialLinks = [

   {
    id: 1,
    icon: <FaInstagramSquare className="social-icon"></FaInstagramSquare>,
    url: "https://www.instagram.com/michel_verjux_/",
  },
]

